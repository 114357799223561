
  <Rotta>
    <Titolo titolo={t.routes.send[$l]}/>
    <ContenitoreBase>
      <p>{@html t.send_data.text_1[$l]}</p>
      <p>{@html t.send_data.text_2[$l]}</p>
      <h3>{@html t.send_data.text_3[$l]}</h3>
      <p>{@html t.send_data.text_4[$l]}</p>
      <h3>{@html t.send_data.text_5[$l]}</h3>
      <p>{@html t.send_data.text_6[$l]}</p>
      <ul>
        <li>{@html t.send_data.text_7[$l]}</li>
        <li>{@html t.send_data.text_8[$l]}</li>
        <li>{@html t.send_data.text_9[$l]}</li>
      </ul>
      <p>{@html t.send_data.text_10[$l]}</p>
      <p>{@html t.send_data.text_11[$l]}<br/>{@html t.send_data.text_12[$l]}</p>
      <h3>{@html t.send_data.text_13[$l]}</h3>
      <p>{@html t.send_data.text_14[$l]}</p>
      <p>{@html t.send_data.text_15[$l]}</p>
      <p>{@html t.send_data.text_16[$l]}</p>
      <p>{@html t.send_data.text_17[$l]}</p>
      <h3>{@html t.send_data.text_18[$l]}</h3>
      <p>{@html t.send_data.text_19[$l]}</p>
      <p>{@html t.send_data.text_22[$l]}</p>
      <h3>{@html t.send_data.text_23[$l]}</h3>
      <p>{@html t.send_data.text_24[$l]}</p>
      <p>{@html t.send_data.text_25[$l]}</p>
      <div>
        <pre class="inline blocco-di-codice">
<div>
  &#123;
    <b>inserted_at</b>    : [time]      <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.data_creazione[$l]}
    <b>reservation_id</b> : [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.id_prenotazione[$l]}
    <b>arrival_date</b>   : [date]      <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.prima_data_arrivo[$l]}
    <b>departure_date</b> : [date]      <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.ultima_data_partenza[$l]}
    <b>status</b>         : [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.stato[$l]}
    <b>rate_code</b>      : [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.codice_trattamento[$l]}
    <b>channel_code</b>   : [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.codice_canale[$l]}
    <b>market_code</b>    : [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.codice_mercato[$l]}
    <b>nationality</b>    : [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.nazionalità[$l]}
    <b>cancel_date</b>    : [date]      <span class="text-secondary">{t.campi.opzionale[$l]}</span>   {t.campi.data_cancellazione[$l]}
    <b>zip_code</b>       : [string]    <span class="text-secondary">{t.campi.opzionale[$l]}</span>   {t.campi.cap[$l]}
    <b>stay_dates</b>     : [array]     <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.dettaglio_date[$l]} &#91;
      &#123;
        <b>date</b>           : [date]      <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.data_soggiorno[$l]}
        <b>sold_rooms</b>     : [integer]   <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.camere_vendute[$l]}
        <b>rooms_revenue</b>  : [float]     <span class="text-secondary">{t.campi.opzionale[$l]}</span>   {t.campi.data_ricavo_camere[$l]}
        <b>full_revenue</b>   : [float]     <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.data_ricavo_complessivo[$l]}
        <b>extra_revenues</b> : [array]     <span class="text-primary">{t.campi.opzionale[$l]}</span>     {t.campi.data_ricavo_complessivo[$l]}
          &#91;
            <b>code</b>:        [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.extra_code[$l]}
            <b>revenue</b>:     [float]     <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.extra_revenue[$l]}
          &#93;,
          &#91;
            ...
          &#93;
        <b>adult_guests</b>   : [integer]   <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.data_ospiti_adulti[$l]}
        <b>child_guests</b>   : [integer]   <span class="text-secondary">{t.campi.opzionale[$l]}</span>   {t.campi.data_ospiti_bambini[$l]}
        <b>room_type</b>      : [string]    <span class="text-primary">{t.campi.obbligatorio[$l]}</span>  {t.campi.tipo_camera[$l]}
      &#125;,
      &#123;
        ...
      &#125;
    &#93;
  &#125;
</div>
        </pre>
      </div>
    </ContenitoreBase>
  </Rotta>


<style>
  :global(pre.inline) {
    background-color: #eee;
    border: 1px solid #999;
    width: fit-content;
    color: var(--colore-primario);
    padding: 0 5px;
  }
  :global(pre.inline.blocco-di-codice) {
    padding: 15px;
    margin: 0 auto;
  }
</style>

<script>
  import Rotta from "@/base/componenti/Rotta.svelte"
  import Titolo from "@/base/componenti/Titolo.svelte"
  import ContenitoreBase from "@/base/componenti/ContenitoreBase.svelte"
  import { dizionario_documentazione as t } from "@/documentazione/sorgenti/dizionario_documentazione"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
</script>
