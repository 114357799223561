
  <Principale/>

  <div class="avvertenza-errore-ricarica-pagina">
    <div class="finestra-messaggio-errore">
      <p>
        {t.messaggio_errore[$l]} <br>

        e-mail: <a href="mailto:supporto@hbenchmark.it">supporto@hbenchmark.it</a>
      </p>
      <div class="azioni">
        <Bottone
            variante="primario"
            on:click={aggiorna_pagina}>
          {t.riprova[$l]}
        </Bottone>

        <Bottone
            variante="primario-invertito"
            on:click={esci_da_hbenchmark}>
          {t.riaccedi[$l]}
        </Bottone>
      </div>
    </div>
  </div>


<style>
  /* Variabili globali. */
  :root {
    --colore-primario:                #091ee4;
    --colore-primario-evidenziato:    #636fe2;
    --colore-primario-chiaro:         #B9DDFF;
    --colore-primario-evidenziato:    #636fe2;
    --colore-secondario:              #5f6167;
    --colore-secondario-evidenziato:  #2e2f32;
    --colore-rosso:                   #DC3545;
    --colore-rosso-evidenziato:       #000;
    --colore-verde:                   #34bc6e;
    --colore-verde-evidenziato:       #000;
    --colore-titolo:                  #1A1A64;
    --colore-testo:                   #212529;
    --colore-testo-leggero:           #1A1A64;
    --colore-sfondo:                  #F8F8F9;
    --colore-sfondo-cruscotto:        #F5F8FC;
    --colore-bianco:                  #ffffff;
    --colore-bordo:                   #E6E6EB;
    --colore-linea:                   #C9C9C9;
    --colore-segnaposto:              #818181;
    --colore-header-tabella:          #E6E6EB;
    --colore-sorvolo:                 #F3F3F6;
    --colore-disabilitato:            #dee2e6;
    --colore-ombreggiatura:           rgba(0, 0, 0, 0.1);
  }
  :global(:root){
    --padding-base-main:              12px;
    --padding-base-main-negativo:     -12px;
  }
  :global(*::after) {
    --padding-base-main:              12px;
    --padding-base-main-negativo:     -12px;
  }

  /* Elementi generali. */
  :global(html, body) {
    background:     var(--colore-sfondo);
    color:          var(--colore-testo);
    width:          100vw;
    height:         100vh;
    margin:         0;
    padding:        0;
    overflow:       hidden;
    font-family:    'Inter', sans-serif;
    font-size:      14px;
    line-height:    20.17px;
    letter-spacing: 0.55px;
  }
  :global(h1){
    line-height: 30px;
    font-size: 18px;
    font-weight: 800;
  }
  :global(h2){
    font-size: 24px;
    font-weight: 800;
  }
  :global(*) {
    box-sizing: border-box;
  }
  :global(a, a:visited) {
    color:  var(--colore-primario);
    text-decoration: none;
    position: relative;
  }
  :global(a:before) {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0;
    background-color: var(--colore-primario-evidenziato);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }
  :global(a:hover:before) {
    visibility: visible;
    transform: scaleX(1);
  }
  :global(form) {
    flex-grow:  1;
  }
  :global(form > div.contenitore-tabella) {
    margin-top: 12px;
  }
  :global(.nascosto) {
    display: none !important;
  }
  :global(div.posizione-relativa) {
    position: relative;
  }

  /* Flessibile. */
  :global(div.flessibile) {
    display:          flex;
  }
  :global(div.flessibile.estesi > *) {
    flex:             1 1 0px;
  }
  :global(div.flessibile.singolo-campo > *) {
    width: 50%;
  }
  :global(div.flessibile.marginati > *:not(:last-child)) {
    margin-right:     14px;
  }
  :global(div.flessibile.marginati.in-colonna > *:not(:last-child)) {
    margin-right:     0;
    margin-bottom:    14px;
  }
  :global(div.flessibile.in-colonna) {
    flex-direction:   column;
  }
  :global(div.flessibile.giustificati-in-mezzo) {
    justify-content:  space-between;
  }
  :global(div.flessibile.giustificati-attorno) {
    justify-content:  space-around;
  }
  :global(div.flessibile.giustificati-fine) {
    justify-content:  flex-end;
  }
  :global(div.flessibile.giustificati-centro) {
    justify-content:  center;
  }
  :global(div.flessibile.giustificati-inizio) {
    justify-content:  flex-start;
  }
  :global(div.flessibile.allineati-attorno) {
    align-items:      space-around;
  }
  :global(div.flessibile.allineati-fine) {
    align-items:      flex-end;
  }
  :global(div.flessibile.allineati-centro) {
    align-items:      center;
  }
  :global(div.flessibile.allineati-inizio) {
    align-items:      flex-start;
  }

  /* Tipografia. */
  @font-face {
    font-family: 'Inter';
    src: url('/tipografia/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('/tipografia/Inter-Medium.ttf')  format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('/tipografia/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('/tipografia/Inter-Bold.ttf')     format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Inter';
    src: url('/tipografia/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  /* Dimensioni. */
  :global(body div.avvertenza-dimensioni-schermo) {
    padding:    14px;
  }
  :global(body div.avvertenza-dimensioni-schermo h1) {
    font-size: 2em;
  }
  :global(body div.avvertenza-dimensioni-schermo h2) {
    font-size: 1.5em;
  }

  @media (max-width: 1023px) {
    :global(body > *:not(.avvertenza-dimensioni-schermo)) {
      display:  none !important;
    }
    :global(body div.avvertenza-dimensioni-schermo) {
      display:  block;
    }
  }
  @media (min-width: 1024px) {
    :global(body div.avvertenza-dimensioni-schermo) {
      display:  none;
    }
  }

  /* Errore */
  :global(div.avvertenza-errore-ricarica-pagina){
    display: none;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    background-color: rgba(4, 4, 4, 0.7);
    backdrop-filter: blur(4px);
    justify-content: center;
    align-items: center;
  }
  :global(div.avvertenza-errore-ricarica-pagina.attiva){
    display: flex;
  }
  :global(div.avvertenza-errore-ricarica-pagina div.finestra-messaggio-errore){
    background-color: var(--colore-bianco);
    padding: 50px;
  }
  :global(div.avvertenza-errore-ricarica-pagina div.finestra-messaggio-errore div.azioni){
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
  }
</style>

<script>
  import Bottone from "@/base/componenti/Bottone.svelte"
  import Principale from "@/documentazione/rotte/Principale.svelte"
  import { localizzazione as l } from "@/base/sorgenti/svuby"
  import { utente_corrente } from "@/base/sorgenti/svuby"

  const t = {
    messaggio_errore: {
      it: `Si è verificato un errore. Riprovare o contattare il supporto.`,
      en: `An error occurred. Please retry or contact support.`,
      de: `Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.`
    },
    riprova: {
      it: `Riprova`,
      en: `Retry`,
      de: `Wiederholen`
    },
    riaccedi: {
      it: `Riaccedi alla pagina`,
      en: `Go back to the page`,
      de: `Zurück zur Seite`
    }
  }

  let messaggio_errore_visualizzato = false

  ////
  // Gestisce l'errore.
  function gestisci_errore(messaggio, sorgente, riga, colonna, errore) {
    if (messaggio != null && messaggio.reason != null && messaggio.reason.non_autorizzato) {
      utente_corrente.slogga()
      window.location.href = `/#/accesso?redirect_url=${window.location.href}`
      window.location.reload()
    } else {
      visualizza_messaggio_errore(...arguments)
    }
  }

  ////
  // Visualizza il messaggio di errore.
  function visualizza_messaggio_errore(messaggio, sorgente, riga, colonna, errore) {
    if (arguments.length == 0) return

    console.error("Si è verificato un errore: ")
    if (errore != null) {
      console.error(errore.stack)
      messaggio_errore_visualizzato = true
    } else {
      attiva_modale_errore()
      console.error(...arguments)
      document.querySelector("body > div.messaggio").classList.add("visualizzato")
    }
  }

  // Apre il messaggio di errore
  function attiva_modale_errore() {
    document.getElementsByClassName("avvertenza-errore-ricarica-pagina")[0].classList.add("attiva")
  }

  ////
  // Logout e ritorno alla pagina di accesso
  function esci_da_hbenchmark() {
    utente_corrente.slogga(true)
    window.location.reload()
  }

  // Ricarica la pagina
  function aggiorna_pagina() {
    window.location.reload()
  }

  window.onerror = gestisci_errore
  window.onunhandledrejection = gestisci_errore
</script>
